import styles from "./Invoice.module.css";
import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import DotLoader from "../DotLoader/DotLoader";
import invoicePaid from "../../../icons/invoice paid.png";
import invoiceUnpaid from "../../../icons/invoice unpaid.png";
import invoicePending from "../../../icons/invoicePendingIcon.png";
import download from "../../../icons/downloadcdr.png";
import downloading from "../../../images/downloading.gif";
import { fetchFeatureTogglesList, getInvoice, initiatePayment } from "../../../api/endpoint";
import { notyf } from "../../../notyf";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import { FetchInvoiceResponse } from "../../../models/response/FetchInvoiceResponse";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import emptyTableImage from "../../../images/emptyTable.png";
import { UserDetailsContext } from "../../../contextApi/UserDetailsContext/UserDetailsContext";
import { FeatureToggleContext } from "../../../contextApi/FeatureToggleContext/FeatureToggleContext";
import { redirectToCheckout } from "airwallex-payment-elements";
import { PaymentType } from "../../../domains/PaymentType";
import { InitiatePaymentResponse } from "../../../models/response/InitiatePaymentResponse";

const Invoice = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<FetchInvoiceResponse[]>([]);
  const [search, setSearch] = useState<string>("");
  const [downloadingInvoices, setDownloadingInvoices]
    = useState<{ [key: string]: boolean }>({});
  const { accessToken } = useContext(AuthContext);
  const { user } = useContext(UserDetailsContext);
  const { isPayInvoiceEnabled } = useContext(FeatureToggleContext);
  const [env, setEnv] = useState<"demo" | "prod">("demo");
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const logoURL =
    "https://bitbucket.org/pigeon-cloud/pigeon-ui/raw/e8dcaec4bacc8ad915351326c626a241d6bda5a4/src/images/pegionlogo.png";
  const paymentGatewayIntegrationScriptSource =
    "https://checkout.airwallex.com/assets/elements.bundle.min.js";


  useEffect(() => {
    setIsLoading(true);
    getInvoice(accessToken || "")
      .then((response) => {
        setInvoices(response);
      })
      .catch(() => {
        notyf.error("Unable to fetch invoice data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accessToken]);

  useEffect(() => {
    if (accessToken)
      fetchFeatureTogglesList().then((featureToggles) => {
        setEnv(
          featureToggles.filter((ft) => ft.name == "IS_PROD_ENV")[0].value
            ? "prod"
            : "demo"
        );
      });
  });

  const handleDownloadClick = (invoice: FetchInvoiceResponse) => {
    setDownloadingInvoices(
      (prevState) => ({ ...prevState, [invoice.file]: true })
    );

    const binaryString = window.atob(invoice.file);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([bytes], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${invoice.number}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setDownloadingInvoices((prev) => ({ ...prev, [invoice.file]: false }));
  };

  const handlePayClick = (invoice) => {
    setIsLoading(true);
    setIsRedirecting(false);
    initiatePayment(accessToken ? accessToken : "", invoice.amount, PaymentType.INVOICE, invoice.id)
      .then((response) => {
        try {
          setIsRedirecting(true);
          redirectToPaymentPage(response);
        } finally {
          setIsLoading(false);
        }
      })
      .catch(() => {
        notyf.error("Unable to initiate payment.");
        setIsLoading(false);
      });
  };


  const redirectToPaymentPage = (response: InitiatePaymentResponse) => {
    const paymentGatewayIntegrationScript = document.createElement("script");

    paymentGatewayIntegrationScript.onload = () => {
      redirectToCheckout({
        env: env,
        mode: "payment",
        currency: response.currency,
        intent_id: response.id,
        client_secret: response.clientSecret,
        successUrl: `${window.location.origin}/recharge/confirmation?paymentType=${PaymentType.INVOICE}`,
        failUrl: `${window.location.origin}/recharge/confirmation?paymentType=${PaymentType.INVOICE}`,
        logoUrl: logoURL,
        googlePayRequestOptions: {
          countryCode: "US",
          buttonColor: "black"
        }
      });
    };
    paymentGatewayIntegrationScript.src = paymentGatewayIntegrationScriptSource;
    document.head.appendChild(paymentGatewayIntegrationScript);
  };

  const formatDate = (date: string) => {
    const parsedDate = moment(date, "MM/DD/YYYY at HH:mm");
    return parsedDate.format("MMMM DD, YYYY");
  };

  const filteredInvoices = invoices.filter((invoice) =>
    invoice.number.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className={styles.invoiceContainer}>
      <div className={styles.invoiceHeader}>
        Invoice Details
      </div>
      <div className={styles.invoiceTableContainer}>
        <div className={styles.invoiceTableHeaderContainer}>
          <div className={styles.searchbar}>
            <input
              value={search}
              className={styles.input}
              placeholder="Search your Invoice No."
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon className={styles.searchIcon} />
          </div>
        </div>
        <div className={styles.invoiceListContainer}>
          <div className={classNames(styles.tableHeader,
            filteredInvoices.length === 0 ? styles.borderBottom : "")}>
            <div className={styles.headerCell}>Status</div>
            <div className={styles.headerCell}>Date</div>
            <div className={styles.headerCell}>Invoice No.</div>
            <div className={styles.headerCell}>Amount</div>
            <div className={styles.headerCell}>Download</div>
            {isPayInvoiceEnabled && <div className={styles.headerCell}>Payment</div>}
          </div>
          {isLoading || isRedirecting ? (
            <div className={styles.loaderContainer}>
              <DotLoader />
            </div>
          ) : (
            <div className={styles.bodyContainer}>
              {filteredInvoices.length > 0 ? (
                filteredInvoices.map((invoice, rowIndex) => (
                  <div
                    key={rowIndex}
                    className={classNames(styles.rowDiv,
                      invoice.status === "PAID" ? styles.paid : "",
                      invoice.status === "UNPAID" ? styles.unpaid : "",
                      invoice.status === "PENDING" ? styles.pending : ""
                    )}>
                      <div className={styles.cellDiv}>
                        {invoice.status === "PAID" && (
                          <img
                            src={invoicePaid}
                            alt="invoice paid"
                            className={styles.invoicePaidIcon}
                          />
                        )}
                        {invoice.status === "UNPAID" && (
                          <img
                            src={invoiceUnpaid}
                            alt="invoice unpaid"
                            className={styles.invoiceUnpaidIcon}
                          />
                        )}
                        {invoice.status === "PENDING" && (
                          <img
                            src={invoicePending}
                            alt="invoice pending"
                            className={styles.invoicePendingIcon}
                          />
                        )}
                      </div>
                      <div className={styles.cellDiv}>
                        {formatDate(invoice.createdAt)}
                      </div>
                      <div className={styles.cellDiv}>
                        {invoice.number}
                      </div>
                      <div className={styles.cellDiv}>
                        {invoice.amount}
                      </div>
                      <div className={styles.cellDiv}>
                        {!downloadingInvoices[invoice.file] ? (
                          <img
                            src={download}
                            alt="download"
                            onClick={() => handleDownloadClick(invoice)}
                            className={styles.clickable}
                          />
                        ) : (
                          <img
                            src={downloading}
                            alt="downloading"
                            className={styles.downloading}
                          />
                        )}
                      </div>
                      {isPayInvoiceEnabled && (
                        <div className={styles.cellDiv}>
                          <button
                            className={styles.payButton}
                            onClick={() => handlePayClick(invoice)}
                            disabled={invoice.status === "PAID" || invoice.status === "PENDING" || user?.status !== "ACTIVE"}
                          >
                            {invoice.status === "PAID" ? "Paid" : invoice.status === "PENDING" ? "Pending" : "Pay Now"}
                          </button>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <img
                    src={emptyTableImage}
                    alt="empty image"
                    className={styles.emptyImage}
                  />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoice;
